<template>
  <Chart
    v-if="chartData"
    :size="{ width: width, height: 200 }"
    :data="chartData"
    :margin="{}"
    :direction="'horizontal'">

    <template #layers>
      <Line :dataKeys="['name', 'pl']" type="monotone" :line-style="{stroke: '#FF7A00'}"/>
      <Grid strokeDasharray="2,2"/>
    </template>

  </Chart>
</template>

<script>
import {Chart, Grid, Line} from 'vue3-charts'

export default {
  name: 'AdminDashboardProperty',
  components: {
    Chart, Grid, Line
  },
  props: {
    width: {
      type: Number,
      default: 400
    },
    data: {
      type: Array
    }
  },
  data() {
    return {
      chartData: null,
    }
  },
  mounted() {
    if (window.innerWidth < 900) {
      this.chartData = this.getEveryNthElement(this.$props.data);
    } else {
      this.chartData = this.$props.data;
    }
  },
  methods: {
    toggleFilterBox() {
      this.filterBoxActive = !this.filterBoxActive
    },
    selectOption(option) {
      this.toggleFilterBox();
      this.selectedOption = option;
    },
    getEveryNthElement(arr) {
      const totalElements = arr.length;

      // Calculate the step size
      const stepSize = Math.floor(totalElements / 5);

      // Return an array with every nth element
      return arr.filter((_, index) => index % stepSize === 0).slice(0, 5);
    }
  },
}
</script>

<style>
</style>
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'SortingDropdown',
    props: {
        sortingOptions: {
            type: Array,
        },
        title: {
            type: String,
            default: null
        },
        selectedValue: {
            type: String,
            default: null
        }
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
    },
    computed: {
        selected() {
            let selected = this.$props.sortingOptions[0];
            this.$props.sortingOptions.forEach((option) => {
                if (option.value === this.$props.selectedValue) {
                    selected = option;
                }
            });
            return selected;
        }
    },
    data() {
        return {
            filterBoxActive: false,
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(selectedOption) {
            this.$emit('sort', selectedOption.value);
            this.filterBoxActive = false;
        },
    }
}